import { render, staticRenderFns } from "./Phrase.vue?vue&type=template&id=68299aea&"
import script from "./Phrase.vue?vue&type=script&lang=js&"
export * from "./Phrase.vue?vue&type=script&lang=js&"
import style0 from "./Phrase.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports